jQuery( 'body' ).ready( function ( $ ) {
  if ( $( 'div#wpadminbar' ).length === 1 ) {
    $( '#cafeto_menu' ).css( 'top', '32px' );
    $( '#cafeto_menu_landing' ).css( 'top', '32px' );
  }

  // JS slider new frame section
  if ( $( '.slick-slider-new-frame' ).length > 0 ) {
    $( '.slick-slider-new-frame' ).slick( {
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 4,
      arrows: true,
      prevArrow: $( '.slider-card-icon-prev' ),
      nextArrow: $( '.slider-card-icon-next' ),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 578,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          },
        },
      ],
    } );
  }

  // JS slider for Case Studies Stories
  if ( $( '.slick-case-studies-stories' ).length > 0 ) {
    $( '.slick-case-studies-stories' ).slick( {
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      arrows: true,
      prevArrow: $( '.icon-prev' ),
      nextArrow: $( '.icon-next' ),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          },
        },
      ],
    } );
  }

  // Close search bar when click outside the bar
  $( document ).on( 'mouseup', function ( e ) {
    var container = $( '#collapseSearchBar' );
    if ( !container.is( e.target ) && container.has( e.target ).length === 0 && $( '#btn-search-id' ).attr( 'aria-expanded' ) === 'true' ) {
      $( '#btn-search-id' ).click();
    }
  } );

  // Close menu when click outside (mobile version)
  $( document ).on( 'mouseup', function ( e ) {
    var container = $( '#cafeto-principal-menu-items' );
    if ( !container.is( e.target ) && container.has( e.target ).length === 0 && $( '#button-menu-toggler' ).attr( 'aria-expanded' ) === 'true' ) {
      $( '#button-menu-toggler' ).click();
    }
  } );
} );

/**
 * Validate Email Format
 * @param  {String} string  example@host.com
 * @return {Boolead}        true/false
 */
window.validEmail = function ( string ) {
  let str = string.trim();
  if ( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test( str ) ) {
    return true;
  }
  return false;
};

/**
 * Validate number range
 * @param  {String} value
 * @param  {int} min
 * @param  {int} max
 * @return {Boolead} true/false
 */
window.validNumberRange = function ( value, min, max ) {
  let str = value.trim();

  if ( validInput( str ) ) {
    if ( /^\d*$/.test( str ) ) {
      if ( ( value >= min ) && ( value <= max ) ) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Validate an input is not empty
 * @param  {String} string input value
 * @return {Boolean}        true/false
 */
window.validInput = function ( string ) {
  return ( ( string ).trim().length > 0 );
};

/**
 * Validate an input is not empty
 * @param  {String} string input value
 * @return {Boolean}        true/false
 */
window.validInputText = function ( string, number ) {
  let str = string.replace( /\s+/g, '' );

  return ( ( str ).trim().length >= number );
};

window.validPhone = function ( string ) {
  let str = string.replace( /\s+/g, '' );

  if ( str !== ' ' ) {
    return ( /[0-9\-\(\)\s]+/.test( str ) && ( str ).trim().length >= 10 );
  } else {
    return true;
  }
};

window.isNullOrBlank = function ( string ) {
  let str = string.replace( /\s+/g, '' );

  if ( str !== ' ' ) {
    return ( ( str ).trim().length >= 10 );
  } else {
    return true;
  }
};

/**
 * This function adds a div that shows a valid or incorrect type alert, getting the description
 * to show.
 *
 * @param idDiv string Get the id with which will declare the div.
 * @param successOrDanger string Get the success or danger of painting the div through the
 *     bootstrap class.
 * @param description string Get the content, which will go inside the div as an alert for the
 *     user.
 *
 * @returns returns a div with a description.
 */
window.validateInputs = function ( idDiv, successOrDanger, description ) {
  var valInput = '';

  valInput += '<div id="validate-' + idDiv + '" class="col-md alert alert-' + successOrDanger + '"';
  valInput += 'role="alert" style="margin-top: 2%;">';
  valInput += description;
  valInput += '<button type="button" class="close" data-dismiss="alert" aria-label="Close">';
  valInput += '<span aria-hidden="true">&times;</span>';
  valInput += '</button>';
  valInput += '</div>';
  return valInput;
};
